import { App } from 'vue'
import BaseButton from '@/components/BaseButton/index'
import BaseTable from '@/components/BaseTable'
import BaseTab from './BaseTab'
import BaseEcharts from '@/components/BaseEchart'
import CommonCard from '@/components/CommonCard'
import BaseDialog from '@/components/BaseDialog'
import BaseBreadCrumb from '@/components/BaseBreadCrumb'
import StatisticsComp from '@/components/BaseLayout/UseBaseLayoutComp/use-subLibrary-statistics/index.vue'
import SubLibraryListComp from '@/components/BaseLayout/UseBaseLayoutComp/use-nav-list/index.vue'
import BaseUpload from '@/components/BaseUpload'
import BaseMainComp from '@/components/BaseMainComp'

const components = {
  BaseButton,
  BaseTable,
  BaseTab,
  BaseEcharts,
  CommonCard,
  BaseDialog,
  BaseBreadCrumb,
  StatisticsComp,
  SubLibraryListComp,
  BaseUpload,
  BaseMainComp,
}

// 插入全局组件
export default function install(app: App) {
  Object.keys(components).forEach((item) => {
    app.component(components[item as keyof typeof components]['name'], components[item as keyof typeof components])
  })
}
