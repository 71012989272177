import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
// 引入pinia实例
import pinia from './store'
//element中文
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import piniaPersist from 'pinia-plugin-persist'
import vue3JsonExcel from 'vue3-json-excel'

import globalCom from '@/components/index'

// 引入路由实例
import router from './router/router'
//数据可视化
import * as ECharts from 'echarts'

//国际化
import vueI18n from '@/lang/lang'
// import lang from "./lang";

import ImgLzay from './directives/imgLazy'
//注册图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'animate.css/animate.min.css' //动画

import ProtvistaSequence from 'protvista-sequence'
import ProtvistaTrack from 'protvista-track'
import ProtvistaManager from 'protvista-manager'
import ProtvistaNavigation from 'protvista-navigation'
// import ProtvistaMsa from "protvista-msa";

window.customElements.define('protvista-sequence', ProtvistaSequence)
window.customElements.define('protvista-track', ProtvistaTrack)
window.customElements.define('protvista-manager', ProtvistaManager)
window.customElements.define('protvista-navigation', ProtvistaNavigation)
// window.customElements.define("protvista-msa", ProtvistaMsa);
// import 'default-passive-events'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 注册全局指令
// 图片懒加载
app.directive('img-lazy', ImgLzay)
//数据可视化
app.config.globalProperties.$ECharts = ECharts

pinia.use(piniaPersist)
// 使用插件

app.use(router)
app.use(vueI18n as any)
app.use(ElementPlus, {})
app.use(globalCom)
app.use(vue3JsonExcel)
app.use(pinia)
app.mount('#app')
