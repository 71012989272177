<template>
  <!-- 路由出口 -->
  <!-- 路由匹配到的组件将渲染在这里 -->
  <Suspense>
    <router-view></router-view>
  </Suspense>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

onMounted(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
})
</script>

<style>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #fff;
}

/* 默认滚动条颜色 */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
}

/* 游览器滚动条鼠标进入变色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}
</style>
