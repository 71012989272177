<template>
  <div>
    <el-upload
      :model-value="file"
      :auto-upload="false"
      :accept="accept"
      drag
      action="#"
      :limit="1"
      ref="baseUploadRef"
      :on-exceed="limitFile"
      :on-remove="removeFile"
      :on-change="uploadChange"
    >
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">{{ title }}</div>
      <template #tip>
        <div class="el-upload__tip"></div>
      </template>
    </el-upload>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { genFileId } from 'element-plus'
import type { UploadInstance, UploadRawFile, UploadFile } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'

defineProps<{
  title: string
  accept: string
  file: any
}>()
const emits = defineEmits(['update:file'])

const baseUploadRef = ref<UploadInstance | null>(null)
// 限制文件
const removeFile = () => {
  emits('update:file', {})
  baseUploadRef.value?.clearFiles()
}
// 移除文件
const limitFile = (files: File[]) => {
  baseUploadRef.value?.clearFiles()
  const file = files[0] as UploadRawFile
  file.uid = genFileId()
  emits('update:file', file)
  baseUploadRef.value?.handleStart(file)
}
// 文件改变
const uploadChange = (file: UploadFile) => {
  emits('update:file', file.raw)
}
</script>

<script lang="ts">
export default {
  name: 'BaseUpload',
}
</script>

<style scoped lang="scss"></style>
